import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { PAGE_DATA, CheckPerm, CheckSub } from "../utils/apis";
import AccessDenied from "../pages/errors/AccessDenied";
import SubscriptionError from "../pages/errors/SubscriptionError";
import LoggedOut from "../pages/errors/LoggedOut";
import useAuth from "../hooks/useAuth";
import Settings from "../pages/dashboard/Settings";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
};

const useBasePath = (location, params) =>
  Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );

const useCurrentRole = (location, params) => {
  let pathname = useBasePath(location, params);
  if (pathname === "/settings" && params && params.tab) {
    pathname = `${pathname}/${params.tab || ""}`;
  }
  const perm = PAGE_DATA.map((item) => {
    if (item.path === pathname) {
      return { perm: item.permission, code: item.code };
    }
    return "";
  }).filter((obj) => Object.keys(obj).includes("perm"));
  return perm && perm.length > 0
    ? perm[0]
    : { perm: "no_permission", code: "" };
};
export default function RoleBasedGuard({ children }) {
  const location = useLocation();
  const params = useParams();
  const { isAuthenticated } = useAuth();

  const { perm, code } = useCurrentRole(location, params);
  const checkSubscription = CheckSub(code);

  const checkPerm = CheckPerm(perm);

  if (!isAuthenticated) {
    return <LoggedOut />;
  }
  if (checkSubscription !== 1) {
    return <SubscriptionError />;
  }
  if (perm !== "no_permission" && perm !== "" && !checkPerm) {
    return (
      (perm === "view_dashboard_analytics" && <Settings />) || <AccessDenied />
    );
  }
  // eslint-disable-next-line
  return <>{children}</>;
}
