import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import loadScript from "load-script2";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
  IconButton,
  FormControlLabel,
  Autocomplete,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import { CopyToBoard, LGAS, MONNIFY_KEY, STATES } from "../../../utils/apis";
// utils
import Close from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { Icon } from "@iconify/react";

const FIELDS = [
  {
    title: "Your Payment",
    subtitle: "Keep track of all your payments and refunds.",
    values: ["accounts"],
    value: "payment",
    buttonText: "Payment methods",
    isOptional: false,
    tab: "payment",
  },
  {
    title: "Payment methods",
    subtitle:
      "Add a payment method using our secure payment system, then start planning your next trip.",
    values: ["username"],
    value: "add_payment",
    buttonText: "Add payment method",
    isOptional: true,
    tab: "payment",
  },
  {
    title: "Dedicated Account",
    subtitle:
      "Process and confirm bank transfers automatically with a dedicated account.",
    values: ["dedicated"],
    value: "dedicated_account",
    buttonText: "Generate",
    isOptional: true,
    tab: "payment",
  },
  {
    title: "How you'll get paid",
    subtitle:
      "Add at least one payout method so we know where to send your money.",
    values: ["email"],
    value: "payout",
    buttonText: "Set up payout",
    tab: "payout",
    isOptional: false,
  },
];

const MOBILE_FIELDS = [
  { title: "Payment methods", value: "payment" },
  { title: "Payout methods", value: "payout" },
];

Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setValue: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  BANKS: PropTypes.array,
};
function Edit({
  open,
  title,
  subtitle,
  type,
  values,
  setValue,
  isLoading,
  handleSave,
  handleClose,
  BANKS,
  enqueueSnackbar,
}) {
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ position: "relative" }}>
        <DialogTitle sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}>
          {title}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close sx={{ color: "rgba(64, 60, 60, 1)" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 1 }}>
        <DialogContentText
          sx={{ mb: 5, fontSize: { md: 14, xs: 13 }, fontWeight: 400 }}
        >
          {subtitle}
        </DialogContentText>
        {type === "add_payment" && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            By clicking proceed, you may be charged a sum of NGN50.00 to verify
            your payment method.
          </Typography>
        )}
        {type === "dedicated_account" && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {values.bankName
              ? ""
              : "A dedicated account lets you process and confirm bank transfers seamlessly at the point of booking and get paid instantly with no delay."}
          </Typography>
        )}
        {type === "dedicated_account" && values.bankName && (
          <Stack
            spacing={{ xs: 2, md: 1 }}
            alignItems="center"
            justifyContent="center"
            sx={{ pb: 4 }}
          >
            <Typography variant="subtitle2">{values.bankName}</Typography>
            <Button
              onClick={() =>
                CopyToBoard(
                  values.accountNumber,
                  enqueueSnackbar,
                  "Account number copied successfully"
                )
              }
            >
              <Stack alignItems="center">
                <Typography variant="h3">{values.accountNumber}</Typography>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  CLICK TO COPY
                </Typography>
              </Stack>
            </Button>
            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              {values.accountName}
            </Typography>
          </Stack>
        )}
        {type === "dedicated_account" && (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontWeight: 600 }}
          >
            {(values.bankName &&
              "Your customer can pay you using this account number. Payments into this account will be automatically sent to your Payout account.") ||
              "Please note that, to use this feature, you will need to set your payout account information."}
          </Typography>
        )}
        {type === "username" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Preferred Name"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="username"
                  value={values.username || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      username: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "email" && (
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <FormControlLabel
              label="Email Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
                width: 1,
              }}
              control={
                <TextField
                  fullWidth
                  name="email"
                  value={values.email || ""}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      email: e.target.value || "",
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(64, 60, 60, 1)",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "payout" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="Country"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={BANKS}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: {
                        md: "rgba(64, 60, 60, 1)",
                        xs: "rgba(95, 95, 95, 1)",
                      },
                    },
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  value={values.bank || {}}
                  name="country"
                  onChange={(event, newValue) => {
                    if (newValue.name) {
                      setValue((prev) => ({
                        ...prev,
                        bank: { code: newValue.code, name: newValue.name },
                        accountName: "",
                      }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select your payout bank"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Account Number"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  type="number"
                  name="account_number"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      accountNumber: e.target.value || "",
                      accountName: "",
                    }))
                  }
                  value={values.accountNumber || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Account Name"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: {
                  md: "1.5px solid rgba(0, 0, 0, 1)",
                  xs: "1px solid rgba(0, 0, 0, 0.5)",
                },
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="account_name"
                  disabled
                  value={values.accountName || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
        {type === "address" && (
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              label="State of Residence"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={STATES}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.state || ""}
                  name="state"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({
                        ...prev,
                        state: newValue,
                        city: "",
                      }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select state of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="City / Local Government Area"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <Autocomplete
                  fullWidth
                  options={
                    values.state
                      ? LGAS.filter((item) => item.state === values.state)[0]
                          .lgas || []
                      : []
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                      py: 0,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "rgba(64, 60, 60, 1)",
                    },
                  }}
                  getOptionLabel={(option) => option || ""}
                  value={values.city || ""}
                  name="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue((prev) => ({ ...prev, city: newValue }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select city of residence"
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Address"
              labelPlacement="top"
              sx={{
                "& span": {
                  fontSize: 13,
                  fontWeight: 300,
                  color: "rgba(64, 60, 60, 0.7)",
                  pt: 0.5,
                  pl: 2,
                },
                alignItems: "start",
                border: "1.5px solid rgba(0, 0, 0, 1)",
                borderRadius: 1,
                mx: 0,
              }}
              control={
                <TextField
                  fullWidth
                  name="address"
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      address: e.target.value || "",
                    }))
                  }
                  value={values.address || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "rgba(248, 250, 252, 1)",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputAdornment-root p": {
                      color: "rgba(9, 9, 20, 1)",
                      fontWeight: 500,
                    },
                    "& input": {
                      py: 0.5,
                    },
                  }}
                />
              }
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start" }}>
        <LoadingButton
          onClick={handleSave}
          loading={isLoading}
          sx={{
            px: 4,
            py: 1.2,
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500,
          }}
          variant="contained"
        >
          {(type === "payout" && !values.accountName && "Verify") ||
            (type === "add_payment" && "Proceed") ||
            (type === "dedicated_account" &&
              !values.bankName &&
              "Generate Account") ||
            (type === "dedicated_account" && values.bankName && "Close") ||
            "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function Account() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, updateSettings, getBanks, getAccountName } = useAuth();
  const { dedicatedAccount } = user;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [values, setValues] = useState({});
  const [banks, setBanks] = useState([]);
  const [selectedTab, setSelectedTab] = useState("payment");
  const [monnifyLoaded, setMonnifyLoaded] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setSelected({});
    setValues({});
  };
  const handleAddPaymentMethod = async () => {
    try {
      setLoading(true);
      if (!monnifyLoaded) {
        await loadScript("https://sdk.monnify.com/plugin/monnify.js");
        setMonnifyLoaded(true);
      }
      // eslint-disable-next-line
      MonnifySDK.initialize({
        amount: 50,
        currency: "NGN",
        customerName: `${user.user.fname} ${user.user.lname}`,
        customerEmail: user.user.email,
        apiKey: MONNIFY_KEY,
        contractCode: "0365452924",
        paymentDescription: "Add Payment Method",
        isTestMode: true,
        paymentMethods: ["CARD"],
        onComplete: function (response) {
          //Implement what happens when transaction is completed.
          const reference = response.transactionReference || "";
          setValues((prev) => ({ ...prev, reference }));
          console.log(response);
          handleSave(reference);
        },
        onClose: function (data) {
          //Implement what should happen when the modal is closed here
        },
      });
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
  };
  const handleSave = async (reference) => {
    try {
      if (selected.value === "dedicated_account") {
        if (dedicatedAccount && dedicatedAccount.bankName) {
          handleClose();
          return;
        }
        setLoading(true);
        const data = await updateSettings({}, selected.value);
        enqueueSnackbar("Dedicated account has been generated successfully!", {
          variant: "success",
        });
        if (data && data.dedicatedAccount) {
          setValues(
            data.dedicatedAccount && data.dedicatedAccount.bankName
              ? data.dedicatedAccount
              : {}
          );
        }
      }
      if (selected.value === "add_payment") {
        if (typeof reference === "string" || values.reference) {
          setLoading(true);
          await updateSettings(
            { reference: values.reference || reference },
            selected.value
          );
          enqueueSnackbar("Payment method has been added successfully!", {
            variant: "success",
          });
          handleClose();
        } else {
          handleAddPaymentMethod();
          return;
        }
      }
      if (selected.value === "payout") {
        if (values.accountName) {
          setLoading(true);
          await updateSettings(values, selected.value);
          enqueueSnackbar("Payout account has been updated successfully!", {
            variant: "success",
          });
          handleClose();
        } else if (values.accountNumber && values.bank.code) {
          setLoading(true);
          const name = await getAccountName(
            values.bank.code,
            values.accountNumber
          );
          setValues((prev) => ({ ...prev, accountName: name }));
          enqueueSnackbar(
            "Account verified! Please click the SAVE button to proceed.",
            {
              variant: "success",
            }
          );
        } else {
          throw new Error(
            "Please enter a valid bank and account number to proceed."
          );
        }
      }
    } catch (err) {
      enqueueSnackbar(err.message || err, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    const get = async () => {
      try {
        setBanks(await getBanks());
      } catch (err) {
        console.log(err);
      }
    };
    get();
  }, [getBanks]);
  useEffect(() => {
    if (!isMobile) {
      setSelectedTab("payment");
    }
  }, []);
  return (
    <>
      <Grid container spacing={{ md: 3, xs: 0 }}>
        <Grid item xs={12} md={8}>
          {!isMobile && (
            <Tabs
              sx={{
                mb: 5,
                "& button": {
                  color: "rgba(98, 98, 98, 1)!important",
                  fontSize: 14,
                  fontWeight: 500,
                },
                "& .Mui-selected": {
                  color: "rgba(65, 177, 26, 1)!important",
                },
              }}
              onChange={(e, v) => setSelectedTab(v)}
              value={selectedTab}
            >
              <Tab value="payment" label="Payment" />
              <Tab value="payout" label="Payout" />
            </Tabs>
          )}
          {isMobile && !selectedTab && (
            <Stack
              spacing={0}
              sx={{
                bgcolor: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 0px 14.8px 10px rgba(0, 0, 0, 0.01)",
                borderRadius: "13px",
              }}
              divider={
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ borderColor: "rgba(0, 0, 0, 0.1)" }}
                />
              }
            >
              {MOBILE_FIELDS.map((item, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ p: 2 }}
                  key={index}
                  onClick={() => {
                    setSelectedTab(item.value);
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "rgba(95, 95, 95, 1)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <IconButton
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectedTab(item.value);
                    }}
                  >
                    <Icon
                      icon="lsicon:right-outline"
                      style={{
                        width: 24,
                        height: 24,
                        color: "rgba(65, 177, 26, 1)",
                      }}
                    />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}
          {Boolean(selectedTab) && (
            <Stack
              spacing={{ md: 2, xs: 1 }}
              sx={{
                pb: { md: 20, xs: 1 },
                borderRadius: { md: 0, xs: 1 },
                bgcolor: { md: "transparent", xs: "common.white" },
                px: { md: 0, xs: 2 },
              }}
              divider={
                isMobile && <Divider flexItem orientation="horizontal" />
              }
            >
              {FIELDS.filter((field) => field.tab === selectedTab).map(
                (item, index) => (
                  <Card
                    key={index}
                    sx={{
                      py: { md: 1, xs: 2 },
                      px: { md: 3, xs: 1 },
                      boxShadow: {
                        md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                        xs: "none",
                      },
                      borderRadius: { md: 1, xs: 0 },
                    }}
                  >
                    <Stack
                      direction={{ md: "row", xs: "column" }}
                      alignItems={{ md: "center", xs: "start" }}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack alignItems="start" spacing={0.5}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 13,
                            fontWeight: 300,
                            color: "rgba(98, 98, 98, 1)",
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                      </Stack>
                      <Button
                        variant={isMobile ? "contained" : "text"}
                        size="small"
                        onClick={() => {
                          if (item.value === "payment") {
                            navigate("/settings/payment_methods");
                          } else {
                            setSelected(item);
                            if (item.value === "payout") {
                              setValues({
                                bank: {
                                  code:
                                    (user &&
                                      user.payoutAccount &&
                                      user.payoutAccount.bankCode) ||
                                    "",
                                  name:
                                    (user &&
                                      user.payoutAccount &&
                                      user.payoutAccount.bankName) ||
                                    "",
                                },
                                accountName:
                                  (user &&
                                    user.payoutAccount &&
                                    user.payoutAccount.accountName) ||
                                  "",
                                accountNumber:
                                  (user &&
                                    user.payoutAccount &&
                                    user.payoutAccount.accountNumber) ||
                                  "",
                              });
                            } else if (item.value === "dedicated_account") {
                              setValues(
                                dedicatedAccount && dedicatedAccount.bankName
                                  ? dedicatedAccount
                                  : {}
                              );
                            } else {
                              setValues({});
                            }
                            setIsEdit(true);
                          }
                        }}
                        sx={{
                          textDecoration: { md: "underline", xs: "none" },
                          boxShadow: "none",
                          fontWeight: 400,
                          fontSize: 14,
                          flexShrink: 0,
                        }}
                      >
                        {item.value === "dedicated_account" &&
                        dedicatedAccount &&
                        dedicatedAccount.bankName
                          ? "View Account"
                          : item.buttonText}
                      </Button>
                    </Stack>
                  </Card>
                )
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
      {isEdit && (
        <Edit
          open={isEdit}
          title={selected.title}
          subtitle={selected.subtitle}
          type={selected.value}
          handleClose={handleClose}
          values={values}
          setValue={setValues}
          handleSave={handleSave}
          isLoading={isLoading}
          BANKS={banks}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
    </>
  );
}
