import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import shopReducer from "./slices/shop";
import bookingReducer from "./slices/booking";
import earningReducer from "./slices/earning";
import listingReducer from "./slices/listing";
import logReducer from "./slices/log";
import supportReducer from "./slices/support";
import reviewReducer from "./slices/review";
import insightReducer from "./slices/insight";
import globalReducer from "./slices/global";
import messageReducer from "./slices/message";
import ticketReducer from "./slices/ticket";
import checkedinReducer from "./slices/checkedin";
import walletReducer from "./slices/wallet";
import leadReducer from "./slices/lead";
import staffReducer from "./slices/staff";
import roleReducer from "./slices/role";
import transactionReducer from "./slices/transaction";
import calendarReducer from "./slices/calendar";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const globalPersistConfig = {
  key: "global",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sales"],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  shop: shopReducer,
  log: logReducer,
  support: supportReducer,
  booking: bookingReducer,
  listing: listingReducer,
  earning: earningReducer,
  review: reviewReducer,
  insight: insightReducer,
  message: messageReducer,
  ticket: ticketReducer,
  checkedin: checkedinReducer,
  lead: leadReducer,
  wallet: walletReducer,
  staff: staffReducer,
  role: roleReducer,
  transaction: transactionReducer,
  calendar: calendarReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
});

export { rootPersistConfig, rootReducer };
