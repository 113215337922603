import { isString } from "lodash";
import PropTypes from "prop-types";
// material
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Typography, Link, Stack, IconButton } from "@mui/material";
//
import { MBreadcrumbs } from "./@material-extend";

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  isBack: PropTypes.any,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = "" || [],
  sx,
  isBack,
  ...other
}) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          {links && <MBreadcrumbs links={links} {...other} />}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
            sx={{ pt: links ? 1 : 0 }}
          >
            {isBack && (
              <IconButton color="inherit" onClick={isBack}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            <Typography
              variant="subtitle1"
              sx={{ fontSize: { md: 20, xs: 16 }, fontWeight: 500 }}
              gutterBottom
            >
              {heading}
            </Typography>
          </Stack>
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
