import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Button, Stack } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";
import { CheckOnboardingDone } from "../utils/apis";
import { useDispatch } from "../redux/store";

const NoticeStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: 5,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
}));

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
export default function NotificationBar() {
  const pathname = useBasePath();
  const { user, isAuthenticated } = useAuth();
  const { subscription } = user;
  const isDone = CheckOnboardingDone();
  const [notice, setNotice] = useState({
    message: "",
    button: "",
    path: "",
    color: "",
  });

  useEffect(() => {
    setNotice({ message: "", button: "", path: "", color: "" });
    if (isAuthenticated && !isDone && pathname !== "/onboarding") {
      setNotice({
        message: "Activate your subscription to access all features!",
        button: "Continue",
        path: PATH_DASHBOARD.general.subscriptions,
        color: "success",
      });
    }
    if (
      isAuthenticated &&
      subscription &&
      Object.keys(subscription).includes("status") &&
      parseInt(subscription.status, 10) === 0 &&
      pathname !== "/subscription" &&
      pathname !== "/choose-plan"
    ) {
      setNotice({
        message: "Renew your subscription to continue enjoying the platform.",
        button: "Renew",
        path: PATH_DASHBOARD.general.subscriptions,
        color: "error",
      });
    }
  }, [subscription, pathname, isDone, isAuthenticated]);

  return (
    notice.message && (
      <NoticeStyle sx={{ bgcolor: `${notice.color}.light` }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" sx={{ color: "rgba(68,68,68,1)" }}>
            {notice.message}
          </Typography>
          <Button
            color="secondary"
            sx={{ fontWeight: 500, boxShadow: "none" }}
            href={notice.path}
          >
            {notice.button}
          </Button>
        </Stack>
      </NoticeStyle>
    )
  );
}
