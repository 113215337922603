import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import loadScript from "load-script2";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// material
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  Grid,
  ButtonGroup,
  Link,
  Divider,
  Alert,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { fCurrency } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import { MasterCardIcon, VisaIcon, VerveIcon } from "../../../assets";
import { PAYSTACK_KEY, SUBSCRIPTION_STATUS } from "src/utils/apis";
import Label from "src/components/Label";
import { isMobile } from "react-device-detect";

// ----------------------------------------------------------------------
ConfirmCard.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
};

function ConfirmCard({ open, onClose, isEdit, onConfirm, loading }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      sx={{ alignItems: "center", textAlign: "center" }}
    >
      <DialogTitle>
        {(isEdit && "Add New Payment Card") || "Remove Payment Card?"}
      </DialogTitle>
      <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            {(isEdit &&
              "You will be charged a sum of NGN50 to process your card. This amount will be refunded back to your account.") ||
              "Your subscription will not be auto renewed when it expires."}
          </Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ justifyContent: "center", textAlign: "center" }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color={(isEdit && "primary") || "error"}
          onClick={onConfirm}
          sx={{ boxShadow: "none", fontWeight: 500 }}
        >
          Proceed
        </LoadingButton>
        <Button onClick={onClose} sx={{ fontWeight: 500, color: "error.main" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelSubscription.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

function CancelSubscription({ open, onClose, onConfirm, loading }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      sx={{ alignItems: "center", textAlign: "center" }}
    >
      <DialogTitle>Cancel Subscription</DialogTitle>
      <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            Please note that your subscription will end once your current plan
            is exhausted.
          </Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ justifyContent: "center", textAlign: "center" }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={onConfirm}
          sx={{ fontWeight: 500, boxShadow: "none" }}
        >
          Proceed
        </LoadingButton>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

ActivateSubscription.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

function ActivateSubscription({ open, onClose, onConfirm, loading }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      sx={{ alignItems: "center", textAlign: "center" }}
    >
      <DialogTitle>Activate Subscription</DialogTitle>
      <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            Please note that your subscription will activate immediately. You
            will be charged only if your plan has expired.
          </Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ justifyContent: "center", textAlign: "center" }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="success"
          onClick={onConfirm}
          sx={{ color: "common.white", boxShadow: "none" }}
        >
          Proceed
        </LoadingButton>
        <Button
          onClick={onClose}
          sx={{ color: "error.main", boxShadow: "none", fontWeight: 500 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
Subscriptions.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  currentPlan: PropTypes.string,
  currentDuration: PropTypes.string,
  duration: PropTypes.string,
  handleSelected: PropTypes.func,
};
const cardMatch = {
  mastercard: <MasterCardIcon />,
  visa: <VisaIcon />,
  verve: <VerveIcon />,
  "sandbox card scheme": <VisaIcon />,
};
export default function Subscriptions() {
  const location = useLocation();
  const {
    user,
    getPlans,
    addCard,
    removeCard,
    cancelSubscription,
    activateSubscription,
    processSubscription,
  } = useAuth();
  const { subscription, account } = user;
  const paymentMethod = subscription.card ? JSON.parse(subscription.card) : {};
  const status = subscription.status ? parseInt(subscription.status, 10) : 0;
  const [plans, setPlans] = useState([]);
  const [duration, setDuration] = useState("monthly");
  const { enqueueSnackbar } = useSnackbar();
  const statusText = SUBSCRIPTION_STATUS[status];
  const [selectedPlan, setSelectedPlan] = useState({});

  // add card
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [paystackLoaded, setPaystackLoaded] = useState(false);

  const [loadingCancel, setLoadingCancel] = useState(false);
  const [openCancel, setOpenCancel] = useState(
    String(location.pathname).includes("cancel")
  );

  const [loadingActivate, setLoadingActivate] = useState(false);
  const [openActivate, setOpenActivate] = useState(
    String(location.pathname).includes("activate")
  );

  const handlePlanClicked = () => {
    setSelectedPlan({});
  };
  const handleActivateConfirm = async (transCode) => {
    if (
      transCode ||
      (paymentMethod &&
        Object.prototype.hasOwnProperty.call(paymentMethod, "last4"))
    ) {
      try {
        setLoadingActivate(true);
        if (subscription && subscription.planName) {
          await activateSubscription(transCode || "");
        } else {
          await processSubscription(selectedPlan.planId, transCode, duration);
        }

        enqueueSnackbar("Subscription has been activated!", {
          variant: "success",
        });
        setOpenActivate(false);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        handleManualRenewal();
      }
      setLoadingActivate(false);
    } else {
      handleManualRenewal();
    }
  };
  const handleManualRenewal = async () => {
    try {
      let price = subscription.monthlyPrice || selectedPlan.amountMonthly;
      if (subscription.duration === "yearly" || duration === "yearly") {
        price = subscription.yearlyPrice || selectedPlan.amountYearly;
      }
      setLoading(true);
      if (!paystackLoaded) {
        await loadScript("https://js.paystack.co/v1/inline.js");
        setPaystackLoaded(true);
      }
      // eslint-disable-next-line
      const handler = PaystackPop.setup({
        key: PAYSTACK_KEY,
        email: user.user.email || "",
        amount: parseFloat(price) * 100,
        channels: ["card", "bank", "bank_transfer"],
        label: (user.user && `${user.user.fname} ${user.user.lname}`) || "",
        callback: (response) => handleActivateConfirm(response.reference),
      });
      handler.openIframe();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };

  const handleCardRemove = () => {
    setIsEdit(false);
    setOpen(true);
  };
  const handleCardAdd = () => {
    setIsEdit(true);
    setOpen(true);
  };
  const verifyCard = async (transId) => {
    try {
      setLoading(true);
      if (isEdit) {
        await addCard(transId);
        enqueueSnackbar("Card added succesfully!", { variant: "success" });
      } else {
        await removeCard(transId);
        enqueueSnackbar("Card removed succesfully!", { variant: "success" });
      }
      setIsEdit(false);
      setOpen(false);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleConfirm = async () => {
    try {
      if (!isEdit) {
        verifyCard("");
        return;
      }
      setLoading(true);
      if (!paystackLoaded) {
        await loadScript("https://js.paystack.co/v1/inline.js");
        setPaystackLoaded(true);
      }
      // eslint-disable-next-line
      const handler = PaystackPop.setup({
        key: PAYSTACK_KEY,
        email: user.user.email || "",
        amount: 5000,
        subaccount:
          account && Object.prototype.hasOwnProperty.call(account, "code")
            ? account.code
            : "",
        bearer: "account",
        channels: ["card", "bank", "bank_transfer"],
        label: (user.user && `${user.user.fname} ${user.user.lname}`) || "",
        callback: (response) => verifyCard(response.reference),
      });
      handler.openIframe();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleCancelConfirm = async () => {
    try {
      setLoadingCancel(true);
      await cancelSubscription();
      enqueueSnackbar("Subscription has been cancelled!", {
        variant: "success",
      });
      setOpenCancel(false);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoadingCancel(false);
  };

  useEffect(() => {
    const get = async () => {
      try {
        //set subscription
        const plans = await getPlans();
        if (subscription && subscription.planName) {
          const { plan } = subscription;
          setSelectedPlan(
            plans.filter((item) => item.planId === plan).length > 0
              ? plans.filter((item) => item.planId === plan)[0]
              : {}
          );
        }
        setPlans(plans);
      } catch (err) {
        console.log(err);
      }
    };
    get();
  }, [getPlans, subscription]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={2}
            alignItems={{ md: "center", xs: "end" }}
            justifyContent="space-between"
            sx={{ width: 1 }}
          >
            <Stack
              alignItems={{
                md: selectedPlan.name ? "start" : "center",
                xs: "start",
              }}
              alignSelf={selectedPlan.name ? "start" : "center"}
              justifyContent="center"
              sx={{
                alignSelf: {
                  md: selectedPlan.name ? "start" : "center",
                  xs: "start",
                },
                flexGrow: 1,
              }}
              spacing={selectedPlan.name ? 3 : 0}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontSize: { md: 24, xs: 20 }, fontWeight: 500 }}
              >
                {(selectedPlan.name && "Plan details") || "Choose your plan"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: 14,
                  fontWeight: 300,
                  color: "rgba(98, 98, 98, 0.7)",
                }}
              >
                {(selectedPlan.name && (
                  <Box>
                    View the{" "}
                    <Link sx={{ fontSize: 14, fontWeight: 400 }}>
                      Terms of Service
                    </Link>
                    &nbsp; and&nbsp;
                    <Link sx={{ fontSize: 14, fontWeight: 400 }}>
                      privacy policy
                    </Link>
                  </Box>
                )) ||
                  "Get the right plan for your business, Plans can be upgraded in the future"}
              </Typography>
            </Stack>
            {(selectedPlan.name && (
              <Button
                sx={{
                  boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  height: 44,
                  px: 2,
                  fontSize: 14,
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 1)",
                }}
                onClick={() => {
                  if (status === 0 || status === 2) {
                    setOpenActivate(true);
                  } else if (status === 1) {
                    setOpenCancel(true);
                  }
                }}
              >
                {status === 0
                  ? (subscription.planName && "Renew Plan") || "Choose Plan"
                  : (status === 1 && "Cancel Plan") || "Activate Plan"}
              </Button>
            )) || (
              <ButtonGroup
                sx={{
                  boxShadow: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  border: "none",
                }}
              >
                <Button
                  sx={{
                    height: 44,
                    border: "none",
                    bgcolor:
                      duration === "monthly" ? "primary.main" : "common.white",
                    color:
                      duration === "monthly"
                        ? "common.white"
                        : "rgba(98, 98, 98, 1)",
                  }}
                  onClick={() => setDuration("monthly")}
                >
                  Monthly
                </Button>
                <Button
                  sx={{
                    height: 44,
                    border: "none",
                    bgcolor:
                      duration === "yearly" ? "primary.main" : "common.white",
                    color:
                      duration === "yearly"
                        ? "common.white"
                        : "rgba(98, 98, 98, 1)",
                  }}
                  onClick={() => setDuration("yearly")}
                >
                  Yearly
                </Button>
              </ButtonGroup>
            )}
          </Stack>
          {(selectedPlan.name && (
            <Stack
              sx={{ width: 1 }}
              alignItems="start"
              justifyContent="start"
              spacing={4}
            >
              <Stack
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: 2,
                  maxWidth: 600,
                  width: 1,
                  p: 3,
                  py: 2,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {selectedPlan.name}
                  </Typography>
                  {subscription && subscription.planName && (
                    <Label color={statusText.color}>{statusText.label}</Label>
                  )}
                </Stack>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: 20, fontWeight: 700 }}
                >
                  {fCurrency(
                    duration === "monthly"
                      ? selectedPlan.amountMonthly
                      : selectedPlan.amountYearly
                  )}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "rgba(98, 98, 98, 0.7)",
                    }}
                  >
                    &nbsp; /{duration}
                  </Typography>
                </Typography>
                <Grid container sx={{ pt: 5, pb: 4 }} spacing={2}>
                  {(selectedPlan.features || []).map((feature, index) => (
                    <Grid item md={6} xs={12} key={index}>
                      <Stack direction="row" spacing={1}>
                        <Icon
                          icon="icon-park-outline:done-all"
                          style={{ color: "rgba(65, 177, 26, 1)" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "rgba(98, 98, 98, 1)",
                          }}
                        >
                          {feature}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
                <Divider
                  flexItem
                  sx={{ borderColor: "rgba(0, 0, 0, 0.25)", display: "none" }}
                />
                <Button
                  sx={{
                    display: "none",
                    color: "rgba(98, 98, 98, 1)",
                    fontSize: 14,
                    fontWeight: 400,
                    textDecoration: "underline",
                  }}
                >
                  View all Features
                </Button>
              </Stack>
              <Stack
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: 2,
                  maxWidth: 600,
                  width: 1,
                  p: 3,
                  py: 2,
                }}
                spacing={2}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  Billing
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon
                    icon="material-symbols:payments"
                    style={{ color: "rgba(64, 60, 60, 0.7)" }}
                  />
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      Billing Circle
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "rgba(64, 60, 60, 1)",
                      }}
                    >
                      {sentenceCase(duration)} for{" "}
                      {fCurrency(
                        duration === "monthly"
                          ? selectedPlan.amountMonthly
                          : selectedPlan.amountYearly,
                        false,
                        false
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                {duration === "monthly" &&
                  parseFloat(selectedPlan.discount) > 0 && (
                    <Alert
                      severity="success"
                      icon={<Icon icon="ep:warning" />}
                      sx={{
                        py: 0,
                        px: 1,
                        fontSize: 13,
                        fontWeight: 400,
                        color: "rgba(0, 0, 0, 1)",
                      }}
                    >
                      Pay yearly and save{" "}
                      {fCurrency(
                        parseFloat(
                          parseFloat(selectedPlan.yearlySansDiscount) -
                            parseFloat(selectedPlan.amountYearly)
                        )
                      )}
                    </Alert>
                  )}

                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ borderColor: "rgba(0, 0, 0, 0.25)" }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {paymentMethod.cardType ? (
                      cardMatch[paymentMethod.cardType.toLowerCase()]
                    ) : (
                      <Icon icon="ph:empty" />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "rgba(64, 60, 60, 1)",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {paymentMethod.cardType
                        ? `Payment method:\n${paymentMethod.cardType} ending with ${paymentMethod.last4}`
                        : "No Payment Method"}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{
                      color: "rgba(65, 177, 26, 1)",
                      textDecoration: "underline",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={handleCardAdd}
                  >
                    Update
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )) || (
            <Box sx={{ width: 1, "& .swiper-slide": { width: "fit-content" } }}>
              <Swiper
                slidesPerView={isMobile ? 1.2 : "auto"}
                spaceBetween={30}
                centerInsufficientSlides
              >
                {plans.map((plan, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ padding: isMobile ? 0 : 20 }}
                  >
                    <Card
                      sx={{
                        py: 2,
                        px: 4,
                        boxShadow: "0px 1px 33.4px -10px rgba(0, 0, 0, 0.14)",
                        borderRadius: 3,
                        position: "relative",
                        pt: 4,
                      }}
                    >
                      {parseInt(plan.isFeatured, 10) === 1 && (
                        <Box
                          sx={{
                            top: 30,
                            right: "-30px",
                            position: "absolute",
                            bgcolor: "rgba(151, 71, 255, 1)",
                            fontSize: 13,
                            fontWeight: 500,
                            color: "common.white",
                            px: 4,
                            py: 0.5,
                            transform: "rotate(40deg)",
                          }}
                        >
                          Best Choice
                        </Box>
                      )}
                      <Stack sx={{ width: 1, height: 1 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            "&:before": {
                              width: 10,
                              height: 10,
                              bgcolor:
                                index === 0
                                  ? "rgba(65, 177, 26, 1)"
                                  : "rgba(111, 46, 197, 1)",
                              borderRadius: "50%",
                              content: '""',
                              display: "inline-block",
                              filter: "blur(2px)",
                              mr: "10px",
                            },
                            color:
                              index === 0
                                ? "rgba(65, 177, 26, 1)"
                                : "rgba(111, 46, 197, 1)",
                            fontSize: 16,
                            fontWeight: 400,
                            pr: 10,
                          }}
                        >
                          {plan.name}
                        </Typography>

                        <Typography
                          variant="caption"
                          sx={{
                            color: "primary.main",
                            textTransform: "capitalize",
                          }}
                        >
                          {plan.description || <span>&nbsp;</span>}
                        </Typography>
                        <Box sx={{ height: 20, mt: 1 }} />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            my: 2,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "rgba(64, 60, 60, 1)",
                              fontSize: 32,
                              fontWeight: 600,
                            }}
                          >
                            {fCurrency(
                              duration === "monthly"
                                ? plan.amountMonthly
                                : plan.amountYearly,
                              false,
                              false
                            )}
                          </Typography>
                          {index === 1 && (
                            <Typography
                              gutterBottom
                              component="span"
                              variant="body2"
                              sx={{
                                alignSelf: "flex-end",
                                color: "rgba(98, 98, 98, 0.7)",
                                fontSize: 14,
                                fontWeight: 400,
                              }}
                            >
                              / {duration}
                            </Typography>
                          )}
                        </Box>
                        {(parseFloat(plan.discount) > 0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "rgba(98, 98, 98, 0.7)",
                              fontSize: 13,
                            }}
                          >
                            Annual Save {plan.discount}%{" "}
                            <span style={{ textDecoration: "line-through" }}>
                              {fCurrency(plan.yearlySansDiscount || 0)}
                            </span>
                          </Typography>
                        )) || <p>&nbsp;</p>}

                        <Box sx={{ height: 20, mt: 1 }} />
                        <Stack
                          component="ul"
                          spacing={2}
                          sx={{ my: 2, width: 1 }}
                        >
                          {plan.features.slice(0, 5).map((item, i) => (
                            <Stack
                              key={i}
                              component="li"
                              direction="row"
                              alignItems="center"
                              spacing={1.5}
                            >
                              <Icon
                                icon="ph:seal-check-bold"
                                sx={{ width: 20, height: 20 }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  color: "rgba(64, 60, 60, 1)",
                                }}
                              >
                                {item}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                        <Box
                          sx={{
                            flexGrow: 1,
                            flexShrink: 0,
                            mb: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "end",
                            alignSelf: "stretch",
                          }}
                        >
                          <Button
                            onClick={() => setSelectedPlan(plan)}
                            fullWidth
                            size="large"
                            variant={index === 0 ? "contained" : "outlined"}
                            sx={{
                              bgcolor:
                                index === 0
                                  ? "rgba(65, 177, 26, 1)"
                                  : "common.white",
                              borderColor:
                                index === 0 ? "none" : "rgba(151, 71, 255, 1)",
                              color:
                                index === 0
                                  ? "common.white"
                                  : "rgba(151, 71, 255, 1)",
                              boxShadow: "none",
                              fontSize: 16,
                              fontWeight: 500,
                              "&:hover": {
                                bgcolor:
                                  index === 0
                                    ? "rgba(65, 177, 26, 1)"
                                    : "common.white",
                                borderColor:
                                  index === 0
                                    ? "none"
                                    : "rgba(151, 71, 255, 1)",
                                color:
                                  index === 0
                                    ? "common.white"
                                    : "rgba(151, 71, 255, 1)",
                              },
                            }}
                          >
                            Choose
                          </Button>
                        </Box>
                      </Stack>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}
        </Stack>
      </Grid>
      <ConfirmCard
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={handleConfirm}
        isEdit={isEdit}
        loading={loading}
      />
      <CancelSubscription
        open={openCancel}
        onClose={() => {
          setOpenCancel(false);
        }}
        onConfirm={handleCancelConfirm}
        loading={loadingCancel}
      />
      <ActivateSubscription
        open={openActivate}
        onClose={() => {
          setOpenActivate(false);
        }}
        onConfirm={() => {
          handleActivateConfirm("");
        }}
        loading={loadingActivate}
      />
    </Grid>
  );
}
